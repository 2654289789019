function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

window.addEventListener('heyflow-screen-view', (event) => {
    
    /** OFFERS CODE **/
    const offersTextElement = document.querySelector('#text-block-rich-text-66bac272 p');
    const randomNumber = getRandomNumber(50, 150);
    
    if (offersTextElement) {
        offersTextElement.innerHTML = `I ditt område har <strong>${randomNumber}</strong> kunder fått <strong>kostnadsfria</strong> erbjudanden under de senaste 7 dagarna.`;
    }

    /** OFFERS CODE END **/

    /** Autocomplete code **/
    // Reference the correct inputs using names provided
    const inputStreet = document.querySelector('[data-variable="street"]');
    const inputStreetNumber = document.querySelector('[data-variable="street_number"]');
    const inputPostalCode = document.querySelector('[data-variable="postcode"]');
    const inputCity = document.querySelector('[data-variable="city"]');

    console.log("Input street element", inputStreet);

    if (inputStreet) {
        const autocomplete = new google.maps.places.Autocomplete(inputStreet, {
            types: ['address'],
            componentRestrictions: { country: 'se' }
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.error("No details available for input: '" + place.name + "'");
                return;
            }

            const addressComponents = {};
            place.address_components.forEach(component => {
                component.types.forEach(type => {
                    addressComponents[type] = component.long_name;
                });
            });
            console.log(addressComponents);

            const deconstructed = {
                streetName: addressComponents.route || '',
                streetNumber: addressComponents.street_number || '',
                postcode: addressComponents.postal_code || '',
                city: addressComponents.postal_town || addressComponents.locality || addressComponents.administrative_area_level_1 || '',
            };

            // Assign the values to the appropriate fields
            inputStreetNumber.value = deconstructed.streetNumber;
            inputStreet.value = deconstructed.streetName;
            inputPostalCode.value = deconstructed.postcode;
            inputCity.value = deconstructed.city;
        });
    } else {
        console.error('Input element for street not found');
    }

    /** Auto complete code end **/

});

window.addEventListener('heyflow-submit', (event) => {
    // This is where you handle form submission if needed
    // console.log('heyflow submit:', event.detail);
});
